/* eslint-disable react/no-unescaped-entities */
import * as React from 'react';
import { Helmet } from 'react-helmet';
import { Link, graphql } from 'gatsby';
import styled from 'styled-components';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import Lead from '../components/Common/Lead';
import Services from '../components/Common/Services';

const ArticleText = styled.div`
  margin-bottom: 100px;
  
  @media only screen and (min-width: 960px) {
    width: 905px;
  }

  ul {
    margin: 0 0 25px;

    li {
      margin: 0 0 1rem;

      @media only screen and (min-width: 640px) {
        p {
          hyphens: auto;
          text-align: justify;
        }
      }
    }
  }

  h2 {
    text-align: left;
  }

  @media only screen and (min-width: 640px) {
    p {
      hyphens: auto;
      text-align: justify;
    }
  }

  img {
    width: 100%;
  }

  figure {
    img {
      width: 100%;
    }
  }
`;

const siteMetadata = {
  title: 'Olsztyn na przedwojennych pocztówkach - skup pocztówek Olsztyn',
  description: 'Skupujemy stare pocztówki przedwojenne, związane z Olsztynem i okolicami, na których przedstawiona jest architektura Olsztyna. Skontaktuj się z nami.',
  siteName: 'Monety Olsztyn',
  imageUrl: 'https://monety.olsztyn.pl/favicons/splash-screen.png',
  siteUrl: 'https://monety.olsztyn.pl/stare-pocztowki/',
  locale: 'pl_PL',
};

const IndexPage = ({ data }) => (
  <>
    <Helmet htmlAttributes={{ lang: 'pl' }}>
      <title>{siteMetadata.title}</title>
      <meta name="description" content={siteMetadata.description} />
      <meta property="og:locale" content={siteMetadata.locale} />
      <meta property="og:site_name" content={siteMetadata.siteName} />
      <meta property="og:image" content={siteMetadata.imageUrl} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={siteMetadata.siteUrl} />
      <meta property="og:title" content={siteMetadata.title} />
      <meta property="og:description" content={siteMetadata.description} />

      <meta name="twitter:card" content="summary" />
      <meta name="twitter:title" content={siteMetadata.title} />
      <meta name="twitter:description" content={siteMetadata.description} />
      <meta name="twitter:image" content={siteMetadata.imageUrl} />

      <link rel="canonical" href={siteMetadata.siteUrl} />
    </Helmet>

    <h1>Przedwojenne pocztówki to prawdziwy wehikuł czasu. Opowiadają&nbsp;także historię Olsztyna.</h1>

    <ArticleText>
      <Lead
        maxWidth="700px"
        desc="XIX-wieczni pomysłodawcy karty pocztowej pewnie nie spodziewali się, że ich wynalazek stanie się kiedyś znakomitym źródeł historii."
      />

      <figure>
        <GatsbyImage
          image={getImage(data.olsztyn_11_listopada)}
          alt="ulica 11 Listopada w Olsztynie - 1911 rok"
          loading="eager"
        />
        <figcaption>
          Piękna kamienica u&nbsp;zbiegu ulic 11 Listopada i&nbsp;Pieniężnego. Około&nbsp;1911&nbsp;r.
          <br />
          (aktualnie w&nbsp;tym miejscu znajduje się Okrąglak)
        </figcaption>
      </figure>

      <p>
        Z czym kojarzy się Henryk Sienkiewicz? Większość z&nbsp;nas odpowie natychmiast, że oczywiście z&nbsp;Trylogią,
        {' '}
        <em>„Krzyżakami”</em>
        ,
        {' '}
        <em>„Quo Vadis”</em>
        {' '}
        czy
        {' '}
        <em>„W pustyni i&nbsp;w puszczy”</em>
        . Gdyby jednak zapytać o&nbsp;to kolekcjonerów kart pocztowych, odpowiedź brzmiałaby pewnie: pocztówka! Przecież to właśnie nasz laureat Nagrody Nobla w&nbsp;dziedzinie literatury wymyślił to określenie.
      </p>

      <p>Stało się to w&nbsp;1900 r. podczas pierwszej w&nbsp;Warszawie wystawy kart pocztowych (zorganizowanej przez Towarzystwo Dobroczynności). Imprezie towarzyszył konkurs na rodzimą nazwę ilustrowanych kart, ponieważ wówczas takie określenie jeszcze nie funkcjonowało. Zgłoszono około 300 propozycji, a&nbsp;do finału zakwalifikowano takie nazwy jak otwartka, listewka, pisanka (!) oraz pocztówka. Wygrała ta ostatnia, a&nbsp;jej pomysłodawczynią okazała się niejaka Maria z&nbsp;R. Jak się później okazało, był to pseudonim, pod którym ukrył się Henryk Sienkiewicz.</p>

      <figure>
        <GatsbyImage
          image={getImage(data.kleebergstrasse)}
          alt="ulica Piłsudskiego w Olsztynie - 1915 rok"
        />
        <figcaption>Aleja Piłsudskiego, widok od strony ul. Dąbrowszczaków w&nbsp;kierunku Ratusza. Około&nbsp;1915&nbsp;r.</figcaption>
      </figure>

      <h2>Oszałamiająca popularność ilustrowanych kart</h2>

      <p>Autor polskiej nazwy jest jeden, ale sama karta pocztowa ma w&nbsp;pewnym sensie dwóch ojców. Jak wskazują źródła historyczne, pomysłodawcą tego rozwiązania był pruski urzędnik państwowy Heinrich Wilhelm Stephan. To właśnie on w&nbsp;1865 r. podczas Niemieckiej Konferencji Pocztowej w&nbsp;Karlsruhe przedstawił odważny pomysł wprowadzenia karty pocztowej, gdzie na jednej stronie byłoby miejsce na znaczek i&nbsp;adres, a&nbsp;na drugiej - treść korespondencji. Przełożeni Stephana pomysł jednak wówczas odrzucili, nie wierząc jeszcze w&nbsp;jego powodzenie.</p>

      <p>Po pewnym czasie do nowatorskiej myśli wrócił jednak austriacki ekonomista Emanuel Herrmann. To dzięki niemu w&nbsp;1869 r. Poczta Austro-Węgierska jako pierwsza wprowadziła karty pocztowe do obiegu.</p>

      <p>
        Nowy sposób korespondencji stał się niezwykle popularny. Można powiedzieć, że karty były odpowiednikiem dzisiejszego e-maila czy sms-a. Popularyzacji kart sprzyjało bez wątpienia to, że wysyłka była tania. W&nbsp;pierwszym okresie istnienia na kartach korespondencyjnych nie było jeszcze żadnych ilustracji. Szybko to się jednak zmieniło i&nbsp;dużą popularność zyskały chociażby karty z&nbsp;motywami patriotycznymi, satyrycznymi, okolicznościowymi czy po prostu ukazujące najciekawsze miejsca i&nbsp;krajobrazy. Pocztówki pełniły również m.in. funkcję reklamową, ponieważ swoje karty produkowały restauracje, hotele, sklepy czy cukiernie. Jak przypomina Wojciech Rybicki w&nbsp;swoim opracowaniu
        {' '}
        <em>„Krótka historia pocztówki”</em>
        ,
        {' '}
        <em>„na skalę masową ilustrowana karta pocztowa pojawia się pod koniec XIX wieku. W&nbsp;roku 1879 wysłano ich w&nbsp;Europie 350 milionów”</em>
        .
      </p>

      <figure>
        <GatsbyImage
          image={getImage(data.olsztyn_glowny_rynek)}
          alt="Olsztyn główny rynek - 1908 rok"
        />
        <figcaption>Północno-wchodni narożnik Rynku. W&nbsp;tle kościół Najświętszego Serca Jezusowego. Rok&nbsp;1908</figcaption>
      </figure>

      <p>Przez lata karty pocztowe były oczywiście produkowane przy użyciu rozmaitych technik. Oto krótka charakterystyka tych najbardziej znanych, które wykorzystywano pod koniec XIX i&nbsp;na początku XX w.</p>

      <h3 style={{ marginBottom: '3rem' }}>Najpopularniejsze techniki druku pocztówek:</h3>

      <ul>
        <li>
          <strong>Litografia</strong>
          {' '}
          - technika polegająca na wykonaniu rysunku, który ma być powielony na wypolerowanej powierzchni kamienia wapiennego. Rysunek wykonywało się natłuszczającą kredką litograficzną lub tłustą farbą. Powierzchnia kamienia była następnie zakwaszana roztworem kwasu azotowego i&nbsp;gumy arabskiej. Dzięki temu zatłuszczone elementy nie przyjmowały farby drukarskiej, ale przyjmowały za to wodę (rysunek zwilżało się właśnie wodą i&nbsp;nanosiło farbę). Odbitkę w&nbsp;specjalnej prasie wykonywało się poprzez przyłożenie zwilżonego papieru do kamienia.
        </li>
        <li>
          <strong>Chromolitografia</strong>
          {' '}
          - jak przypomina Wojciech Rybicki w&nbsp;
          <em>„Krótkiej historii pocztówki”</em>
          , chromolitografia była rozwinięciem techniki litograficznej. Jest to więc jej kolorowa odmiana. W&nbsp;tym przypadku
          {' '}
          <em>„każdy kolor na wielobarwnej odbitce uzyskiwano z&nbsp;innej płyty kamiennej. Najczęściej stosowano trzy kolory ale zdarzały się pocztówki wydrukowane z&nbsp;większej ilości płyt w&nbsp;niektórych wypadkach nawet z&nbsp;16. Dzięki tej technice powstały piękne pocztówki będące małymi dziełami sztuki użytkowej. Zdobione winietami kwiatowymi, tłoczone oraz złocone. Mankamentem była duża pracochłonność wykonania rysunku”</em>
          .
        </li>
        <li>
          <strong>Światłodruk</strong>
          {' '}
          - bezrastrowa technika druku płaskiego zapewniająca wysoką jakość i&nbsp;powtarzalność druku. Forma światłodrukowa była wykonana z&nbsp;żelatyny z&nbsp;domieszką dwuchromianu potasu. Żelatynę wylewało się na płytę szklaną lub metalową, a&nbsp;następnie naświetlało. Dzięki temu pocztówki były realistyczne i&nbsp;oddawały nawet najdrobniejsze szczegóły.
        </li>
        <li>
          <strong>Offset</strong>
          {' '}
          - technika, która pozwoliła wydawać pocztówki w&nbsp;dużych nakładach. Jest to odmiana druku płaskiego, w&nbsp;której obraz przenoszony jest z&nbsp;płaskiej formy drukowej na podłoże drukowe za pośrednictwem cylindra pośredniego (pokrytego obciągiem z&nbsp;gumy).
        </li>
        <li>
          <strong>Fotooffset</strong>
          {' '}
          - metoda wykonywania form drukarskich dla druku płaskiego za pomocą fotografii reprodukcyjnej.
        </li>
      </ul>

      <h2>Pocztówki opowiadają historię Olsztyna</h2>

      <p>Ogromna popularność kart pocztowych dotarła oczywiście również do Olsztyna. Stało się to około 1890 r., kiedy miasto prężnie się rozwijało, zyskało gospodarczo na znaczeniu, czego konsekwencją był m.in. napływ urzędników, kupców, wojskowych czy nauczycieli z&nbsp;innych części kraju. To prawdopodobnie oni przenieśli do miasta zwyczaj wysyłania kart pocztowych. Jedną z&nbsp;pierwszych kart wydał w&nbsp;Olsztynie księgarz Eugeniusz Buchholz. W&nbsp;kolejnych latach w&nbsp;mieście działało coraz więcej księgarń, wydawnictw, zakładów fotograficznych, czy też specjalnych firm trudniących się tworzeniem pocztówek. Karty napływały też do Olsztyna z&nbsp;największych fabryk działających na różnych krańcach Niemiec.</p>

      <p>
        Jak przypomina Rafał Bętkowski w&nbsp;książce
        {' '}
        <em>„Olsztyn jakiego nie znacie”</em>
        ,
        {' '}
        <em>„działalność wydawnicza firm uległa nasileniu po 1907 r., swoje apogeum osiągając tuż przed 1914 r. i&nbsp;w latach wielkiej wojny”</em>
        . Jak podaje autor, duża w&nbsp;tym zasługa m.in. jednostek wojskowych, które tuż przed wojną rozlokowano wówczas w&nbsp;mieście. Jak opisuje autor,
        {' '}
        <em>„wysyłanie pocztówek choć trochę zaspokajało (...) potrzebę podtrzymywania kontaktów z&nbsp;rodziną”</em>
        .
      </p>

      <figure>
        <GatsbyImage
          image={getImage(data.olsztyn_gmach_sadu_okregowego)}
          alt="Olsztyn gmach Sądu Okręgowego"
        />
        <figcaption>
          Skrzyżowanie al. Piłsudskiego i&nbsp;ul. Dąbrowszczaków.
          <br />
          Gmach Sądu Okręgowego i&nbsp;Ziemskiego. W&nbsp;prawym-dolnym rogu postój taksówek. Lata 30-te.

        </figcaption>
      </figure>

      <p>Mówi się, że złote lata pocztówki skończyły się niestety po 1918 r. Wojna zmieniła ludzi, pocztówki zaczęły być też powoli wypierane przez ilustrowane magazyny, telefon i&nbsp;oczywiście rozwijające się kino. Wciąż były kupowane i&nbsp;wysyłane, ale nie w&nbsp;takiej skali jak w&nbsp;przeszłości. Produkcja wciąż jednak trwała także w&nbsp;Olsztynie. W&nbsp;okresie międzywojennym serie pocztówek wydało m.in. Zjednoczenie Olsztyńskich Handlarzy Książek i&nbsp;Art. Papierniczych. Jak przypomina Rafał Bętkowski, związek Reichsbund Deutscher Burobedarf wydał z&nbsp;kolei serię kart lotniczych z&nbsp;widokami miasta, które wiele dekad później pozwoliły lepiej odtworzyć historię Olsztyna.</p>

      <p>
        Pocztówki musiały w&nbsp;końcu ustąpić miejsca nowocześniejszym środkom przekazu, ale wciąż pełnią bardzo istotną rolę: są pewnego rodzaju wehikułem czasu, sposobem na poznanie często nieznanej historii Olsztyna (szczególnie okres międzywojenny pozostawił po sobie wiele pocztówek z&nbsp;naszego miasta). Znajdujące się w&nbsp;archiwach lub wciąż jeszcze w&nbsp;rodzinnych albumach karty przypominają nam, jak ponad 100&nbsp;lat temu wyglądała nasza dzielnica, ulica, kamienica czy okoliczny park. I&nbsp;jakie historie kryją się za tymi miejscami. O&nbsp;tym, jak wiele zmieniły wiatry historii, zauważał już w&nbsp;latach 60-tych w&nbsp;swoich
        {' '}
        <em>„Spacerkach po Olsztynie”</em>
        {' '}
        warmiński poeta Alojzy Śliwa:
      </p>

      <cite>
        Gdy przypatruję się zupełnie odbudowanemu z&nbsp;gruzów i&nbsp;ruin Olsztynowi, i&nbsp;porównuję z&nbsp;Olsztynem mojej młodości, ledwo go poznaję...
        <br />
        <span className="author">
          „Spacerki po Olsztynie” - Alojzy Śliwa, 1967 r.
        </span>
      </cite>

      <p>***</p>

      <p>
        Jeśli też jesteś wielbicielem starych pocztówek, chciałbyś się nimi wymienić lub je sprzedać,
        {' '}
        <Link to="/kontakt/" title="Przejdź do kontaktu">skontaktuj się</Link>
        {' '}
        z&nbsp;nami. Nasz adres to
        {' '}
        <a href="mailto:kontakt@monety.olsztyn.pl">kontakt@monety.olsztyn.pl</a>
        .
      </p>
    </ArticleText>

    <Services />
  </>
);

export const query = graphql`
  {
    olsztyn_11_listopada: file(relativePath: {regex: "/olsztyn-11-listopada/"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          placeholder: NONE
        )
      }
    }
    kleebergstrasse: file(relativePath: {regex: "/kleebergstrasse/"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          placeholder: BLURRED
          width: 915
        )
      }
    }
    olsztyn_glowny_rynek: file(relativePath: {regex: "/olsztyn-glowny-rynek/"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          placeholder: BLURRED
        )
      }
    }
    olsztyn_gmach_sadu_okregowego: file(relativePath: {regex: "/olsztyn-gmach-sadu-okregowego/"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          placeholder: BLURRED
        )
      }
    }
  }
`;

export default IndexPage;
